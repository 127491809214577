<template>
  <div class="container" v-show="ready">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SlideShow",
  props: {
    speed: {
      type: Number,
      default: 3500
    },
    cycle: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      ready: false,
      slide_number: 0,
      slideTimeout: function() {}
    }
  },
  created: function() {
    this.$nextTick().then(() => {
      this.showNext()
      this.ready = true
    })
  },
  methods: {
    showNext: function() {
      if (this.slide_number == this.$el.children.length) this.slide_number = 1
      else this.slide_number++

      this.$emit("slide-change", this.slide_number)

      for (var i = 0; i < this.$el.children.length; i++) {
        this.$el.children[i].style.opacity = 0
      }

      this.$el.children[this.slide_number - 1].style.opacity = 1

      if (this.cycle)
        this.slideTimeout = setTimeout(() => {
          this.showNext()
        }, this.speed)
    },
    show: function(slidetoshow) {
      clearTimeout(this.slideTimeout)
      this.slide_number = slidetoshow - 1
      this.showNext()
    }
  }
}
</script>

<style scoped>
div {
  position: relative;
}
.container > * {
  position: absolute;
  transition: all 0.2s;
  top: 0;
  left: 0;
}
.container > *:first-child {
  position: relative;
}
img {
  width: 100%;
}
</style>

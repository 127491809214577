import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/",
    //   name: "index",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/category",
    //   name: "category",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/sub-category",
    //   name: "sub-category",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/product",
    //   name: "product",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/about",
    //   name: "about",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/portfolio",
    //   name: "portfolio",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/downloads",
    //   name: "downloads",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/contact",
    //   name: "contact",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    //{
    //  path: "/account",
    //  name: "account",
    //  component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    //},
    {
      path: "/",
      name: "root",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/404-not-found",
      name: "404",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/search-results",
      name: "search-results",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/:url",
      name: "page-by-url",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import api from "@/services/api"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({ basket: state.basket })
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    page: {},
    parents: [],

    basket: { items: [] },

    site_id: "carlstahlarchitectural",
    settings: null,
    main_pages: null,

    homeID: "fUlfItRaR",
    aboutID: "o3Z-CnaLx",
    portfolioID: "eZ_VsuiX0",
    downloadsID: "Qh4eOPzrS",
    contactID: "UTlVI1zuW",
    assembliesID: "0xF9gLFLK",
    posilockID: "suIz6ChJz",
    componentsID: "INdpLye8t",
    wireRopeID: "O_4vvEkER",
    wireRopePosilockID: "GiBaxbivoZ",
    sitemapID: "1GH-CaGQY",
    termsID: "9fx1tXjDa",
    deliveryID: "xh59h7SFE",
    orderingID: "he_kPaK72",
    tradeID: "FnzoMQH0V",
    basketID: "w6rcp5mjV",
    checkoutID: "rBrQZ0RXd",
    successID: "QESFIgJsT",
    failureID: "U---NH_vD",

    // assembliesPage: null,
    // posilockPage: null,
    // componentsPage: null
    checkoutPage: null,

    calculateItemPrice(item) {
      var price = Number(item.variant.cost)
      var length = Number(item.length_mm) / 1000
      if (Number(item.variant.additional_cost) && length) price += Number(item.variant.additional_cost) * (length - 1)
      return price
    }
  },
  getters: {
    isHomePage(state) {
      return state.page.ItemID && state.page.ItemID == state.homeID
    },
    isAboutPage(state) {
      return state.page.ItemID && state.page.ItemID == state.aboutID
    },
    isPortfolioPage(state) {
      return state.page.ItemID && state.page.ItemID == state.portfolioID
    },
    isDownloadsPage(state) {
      return state.page.ItemID && state.page.ItemID == state.downloadsID
    },
    isContactPage(state) {
      return state.page.ItemID && state.page.ItemID == state.contactID
    },
    isBasketPage(state) {
      return state.page.ItemID && state.page.ItemID == state.basketID
    },
    isCheckoutPage(state) {
      return state.page.ItemID && state.page.ItemID == state.checkoutID
    },
    isSuccess(state) {
      return state.page.ItemID && state.page.ItemID == state.successID
    },
    isFailure(state) {
      return state.page.ItemID && state.page.ItemID == state.failureID
    },
    isATopCategory(state) {
      return (
        state.page.ItemID &&
        (state.page.ItemID == state.assembliesID ||
          state.page.ItemID == state.posilockID ||
          state.page.ItemID == state.componentsID ||
          state.page.ItemID == state.wireRopeID)
      )
    },
    isASubCategory(state) {
      return state.page.ItemType && state.page.ItemType == "page.categories"
    },
    isAProduct(state) {
      return state.page.ItemType && state.page.ItemType == "page.products"
    },
    mainMenu(state) {
      if (!state.main_pages) return []

      return state.main_pages.filter(
        (page) =>
          page.ItemID == state.homeID ||
          page.ItemID == state.aboutID ||
          page.ItemID == state.portfolioID ||
          page.ItemID == state.downloadsID ||
          page.ItemID == state.contactID ||
          page.ItemID == state.assembliesID ||
          page.ItemID == state.posilockID ||
          page.ItemID == state.componentsID ||
          page.ItemID == state.wireRopeID
      )
    },
    categoryPages(state) {
      if (!state.main_pages) return []

      return state.main_pages.filter(
        (page) =>
          page.ItemID == state.assembliesID || page.ItemID == state.posilockID || page.ItemID == state.componentsID
      )
    },
    footerMenu(state) {
      if (!state.main_pages) return []

      return state.main_pages.filter(
        (page) =>
          page.ItemID == state.sitemapID ||
          page.ItemID == state.termsID ||
          page.ItemID == state.deliveryID ||
          page.ItemID == state.orderingID ||
          page.ItemID == state.tradeID
      )
    },
    basketSubTotal: (state) =>
      state.basket.items.reduce((total, item) => total + state.calculateItemPrice(item) * item.qty, 0),
    basketVAT: (state, getters) => getters.basketSubTotal * 0.2,
    basketDelivery: (state, getters) => {
      if (!getters.basketSubTotal) return 0
      if (!state.settings) return 0
      // if (!state.settings.cost) return 0
      // if (!state.settings.free_threshold) return 0

      // if (getters.subTotal >= state.settings.free_threshold) return 0

      var isys_small_order_threshold = Number(state.settings.isys_small_order_threshold)
      var isys_small_order_surcharge = Number(state.settings.isys_small_order_surcharge)
      var isys_delivery = Number(state.settings.isys_delivery)
      var isys_free_delivery_threshold = Number(state.settings.isys_free_delivery_threshold)
      var posilock_free_delivery_threshold = Number(state.settings.posilock_free_delivery_threshold)
      var posilock_delivery = Number(state.settings.posilock_delivery)

      var hasOther = false
      state.basket.items.forEach((item) => {
        if (item.sub_category_id == state.wireRopePosilockID || item.top_category_id == state.posilockID) return

        hasOther = true
      })

      if (hasOther) {
        if (getters.basketSubTotal < isys_small_order_threshold) return isys_small_order_surcharge + isys_delivery
        if (getters.basketSubTotal < isys_free_delivery_threshold) return isys_delivery
        return 0
      } else {
        if (getters.basketSubTotal < posilock_free_delivery_threshold) return posilock_delivery
        return 0
      }
    },
    basketGrandTotal: (state, getters) => getters.basketSubTotal + getters.basketDelivery + getters.basketVAT
  },
  mutations: {
    setPage(state, page) {
      state.page = page
    },
    setSpecificPage(state, payload) {
      state[payload.prop] = payload.page
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages
    },
    clearParents(state) {
      state.parents = []
    },
    addParent(state, page) {
      state.parents.unshift(page)
    },
    addBasketItem: (state, payload) => {
      var productIndex = state.basket.items.findIndex(
        (item) =>
          item.product_id == payload.product_id &&
          item.variant._id == payload.variant._id &&
          item.length_mm == payload.length_mm
      )

      if (productIndex == -1) state.basket.items.push(payload)
      else Vue.set(state.basket.items[productIndex], "qty", state.basket.items[productIndex].qty + payload.qty)
    },
    basketUpdateQty: (state, payload) => {
      if (payload.qty > 0) state.basket.items[payload.index].qty = Number(payload.qty)
      else state.basket.items.splice(payload.index, 1)
    },
    basketUpdateLength: (state, payload) => {
      state.basket.items[payload.index].length_mm = Number(payload.length_mm)
    },
    basketEmptyCart: (state) => (state.basket.items = [])
  },
  actions: {
    getParents({ commit, state }) {
      return new Promise((resolve) => {
        commit("clearParents")
        resolve()
        if (state.page.ParentID)
          api.getPageByItemID(state.page.ParentID).then((parent) => {
            commit("addParent", parent)
            if (parent.ParentID)
              api.getPageByItemID(parent.ParentID).then((topparent) => {
                commit("addParent", topparent)
              })
          })
      })
    },
    getSettings({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then((settings) => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getMainPages({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.main_pages)
          api.getPages("ItemType", "page.main").then((main_pages) => {
            commit("setMainPages", main_pages)
            resolve()

            var promises = []

            main_pages.forEach((page) => {
              page.ItemType = page.ItemType.split("=")[0]

              // if (page.ItemID == state.assembliesID) commit("setSpecificPage", { prop: "assembliesPage", page })
              // if (page.ItemID == state.posilockID) commit("setSpecificPage", { prop: "posilockPage", page })
              // if (page.ItemID == state.componentsID) commit("setSpecificPage", { prop: "componentsPage", page })

              if (page.ItemID == state.checkoutID) commit("setSpecificPage", { prop: "checkoutPage", page })

              promises.push(
                api.getPages("", "page.categories=@" + page.ItemID).then((subs) => Vue.set(page, "subs", subs))
              )
            })

            Promise.all(promises).then(() => {
              commit("setMainPages", main_pages)
            })
          })
        else resolve()
      })
    }
  }
})

import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Meta from "vue-meta"
import VueGtag from "vue-gtag";

import { library } from "@fortawesome/fontawesome-svg-core"
import { faBars, faTimes, faPhone, faSearch, faUser, faShoppingCart, faChevronCircleDown, faChevronRight, faTruck, faHeadset, faAward, faRulerCombined, faCreditCard, faFolderOpen, faFilePdf, faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import "luminous-lightbox/dist/luminous-basic.min.css"

library.add(faBars, faTimes, faPhone, faFacebook, faYoutube, faSearch, faUser, faShoppingCart, faChevronCircleDown, faChevronRight, faTruck, faHeadset, faAward, faRulerCombined, faCreditCard, faFolderOpen, faFilePdf, faMinusSquare, faPlusSquare)
Vue.component("font-awesome-icon", FontAwesomeIcon)

const requireComponent = require.context("./components/elements", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false
Vue.use(Meta)

Vue.use(VueGtag, {
  config: { id: "UA-43555491-1"
}
}, router);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
